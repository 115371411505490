import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import AboutUsItem from '../common/aboutUsItem';
import Info from '../common/info';
import {
  BLACK, BLUE, DARK_GRAY, WHITE,
} from '../../constants/colors';
import {
  CONTAINER_WIDTH, MOBILE, TABLET, media, LAPTOP, CONTAINER_LAPTOP_WIDTH,
} from '../../constants/screens';

const AboutUs = ({ sections }) => (
  <AboutUsContainer>
    <MainContent>
      {
          sections.map(({
            id,
            title: sectionTitle,
            subTitle,
            content: {
              0: {
                button: { text, url },
                description: { html },
                title,
              },
            },
            image: {
              alt,
              file: { localFile: { childImageSharp: { sizes } } },
            },
          }, i) => (
            <ListItem key={id}>
              {
                sectionTitle && (
                <Title>
                  {sectionTitle}
                </Title>
                )
              }
              {
                subTitle && (
                <SubTitle>
                  {subTitle}
                </SubTitle>
                )
              }
              <AboutUsItem
                image={sizes}
                reversed={!!(i % 2)}
                maxImageWidth="800px"
                imgAltAttr={alt}
              >
                <Info
                  subTitle={title}
                  text={html}
                  reversed={!!(i % 2)}
                  titleColor={DARK_GRAY}
                  subTitleColor={BLUE}
                  textColor={BLACK}
                  subTitleFontSize="33px"
                  withButton
                  buttonText={text}
                  buttonColor={BLUE}
                  link={url}
                />
              </AboutUsItem>
            </ListItem>
          ))
        }
    </MainContent>
  </AboutUsContainer>
);

AboutUs.propTypes = {
  sections: PropTypes.instanceOf(Array).isRequired,
};

const AboutUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${WHITE};
  padding: 200px 5% 190px 5%;
  
  @media screen and (max-width: ${LAPTOP}) {
    padding: 150px 5% 140px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    padding: 75px 5%;
  }
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${CONTAINER_WIDTH};
  width: 100%;
  align-items: center;
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
  `}
  
  > div:not(:last-child) > div {
    margin-bottom: 130px;
    
    @media screen and (max-width: ${LAPTOP}) {
      margin-bottom: 100px;
    }
    
    @media screen and (max-width: ${TABLET}) {
      margin-bottom: 0;
    }
  }
  
  > div > div {
    @media screen and (max-width: ${TABLET}) {
  
      > div:first-child {
        order: 2;
        margin-bottom: 0;
      }
      
      > div:last-child {
        order: 1;
        margin-bottom: 55px;
      }
    }
  }
  > div:not(:last-child) > div {
    @media screen and (max-width: ${TABLET}) {
      margin-bottom: 100px;
    }
  }
`;

const Title = styled.p`
  font-family: AvenirNextLTProBold;
  font-weight: bold;
  font-size: 19px;
  line-height: 1.92;
  letter-spacing: normal;
  text-transform: uppercase;
  color: ${DARK_GRAY};
  text-align: center;
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 16px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 16px;
  }
`;

const SubTitle = styled.h3`
  font-size: 40px;
  line-height: normal;
  letter-spacing: normal;
  text-transform: uppercase;
  color: ${BLUE};
  text-align: center;
  margin-bottom: 145px;
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 30px;
    margin-bottom: 100px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 24px;
    margin-bottom: 100px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    margin-bottom: 75px;
  }
`;

const ListItem = styled.div`
  width: 100%;
`;

export default AboutUs;
