import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'ramda';
import { graphql } from 'gatsby';
import withCustomLayout from '../enhancers/withCustomLayout';
import Introduction from '../components/about/introduction';
import AboutUs from '../components/about/aboutUs';
// import TeamMembers from '../components/about/teamMembers';
// import Team from '../components/about/team';
import Contacts from '../components/about/contacts';
import { LIGHT_SCHEME } from '../constants/colors';
import withStickyHeader from '../enhancers/withStickyHeader';

const AboutPage = (props) => {
  const {
    data: {
      // users: { edges: users },
      page: { introduction, sections },
    },
  } = props;

  const aboutUsSections = sections.filter(({ meta }) => meta.includes('about'));
  // const team = sections.filter(({ meta }) => meta.includes('team'));
  const contacts = sections.filter(({ meta }) => meta.includes('contacts'));

  return (
    <div>
      <Introduction {...introduction} />
      <AboutUs sections={aboutUsSections} />
      { /*
      <Team data={team[0]} />
      <TeamMembers teamMembers={users.map(u => u.node)} />
      */ }
      {contacts && (
        <Contacts {...contacts[0]} />
      )}
    </div>
  );
};

AboutPage.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default compose(
  withStickyHeader(),
  withCustomLayout({ scheme: LIGHT_SCHEME }),
)(AboutPage);

// eslint-disable-next-line
export const query = graphql`
  query AboutPageQuery {
    page(url: { eq: "/about" }) {
      url
      introduction {
        image {
          file {
            localFile {
              childImageSharp {
                sizes(maxWidth: 1700, quality: 100) {
                  ...GatsbyImageSharpSizes_noBase64
                }
              }
            }
          }
        }
        title
        subTitle
        description
      }
      sections {
        id: _id
        name
        meta
        title
        subTitle
        description
        content {
          id: _id
          title
          description {
            md
          }
        }
        image {
          alt
          file {
            localFile {
              childImageSharp {
                sizes(maxWidth: 640) {
                  ...GatsbyImageSharpSizes_noBase64
                }
              }
            }
          }
        }
        content {
          id: _id
          icon {
            file {
              localFile {
                publicURL
              }
            }
          }
          title
          lead
          button {
            text
            url
          }
          description {
            html
          }
        }
      }
    }
  }
`;
