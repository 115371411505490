import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { pathOr } from 'ramda';
import Icon from '../../svg/offices.svg';
import {
  TURQUOISE,
  WHITE,
} from '../../constants/colors';
import { LAPTOP, MOBILE, TABLET } from '../../constants/screens';

const Contacts = ({ content: [ourOffices, ...address] }) => (
  <ContactsContainer>
    <ContactsContentLeft>
      <ContactsContentLeftContainer>
        <LeftContainerRowHeader>
          <IconWrapper>
            <Icon />
          </IconWrapper>
          <Offices>
            <OfficesTitle>
              {pathOr('', ['title'], ourOffices)}
            </OfficesTitle>
            <OfficesText source={pathOr('', ['description', 'md'], ourOffices)} />
          </Offices>
        </LeftContainerRowHeader>
        {address.map(a => (
          <LeftContainerRowContent key={a.id}>
            <ContactsDataTitle>{a.title}</ContactsDataTitle>
            <OfficesText source={pathOr('', ['description', 'md'], a)} />
          </LeftContainerRowContent>
        ))}
        {address.map(a => (
          <LeftContainerMobileContent key={a.id}>
            <ContactsDataTitle>{a.title}</ContactsDataTitle>
            <OfficesText source={pathOr('', ['description', 'md'], a)} />
          </LeftContainerMobileContent>
        ))}
      </ContactsContentLeftContainer>
    </ContactsContentLeft>
  </ContactsContainer>
);


Contacts.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const ContactsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  background-image: linear-gradient(126deg, #2a159a, #2a159a 46%, #2b55c5);
  
  @media screen and (max-width: ${TABLET}) {
    flex-direction: column;
  }
`;

const ContactsContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.55;
  padding: 155px 5% 140px 10%;
  align-items: center;
  justify-content: center;
  
  @media screen and (max-width: ${LAPTOP}) {
    padding: 120px 5% 110px 10%;
  }
  
  @media screen and (max-width: ${TABLET}) {
    padding: 75px 5%;
  }
`;

const ContactsContentLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LeftContainerRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const LeftContainerRowHeader = styled(LeftContainerRow)`
  margin-bottom: 40px;
  
  @media screen and (max-width: ${TABLET}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 90px;
  }
`;

const LeftContainerRowContent = styled(LeftContainerRow)`
  margin-bottom: 10px;
  
  > p:first-child {
    margin-right: 40px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    display: none;
  }
`;

const IconWrapper = styled.div`
  max-width: 250px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 40px;
  
  > svg {
    max-width: 183px;
    width: 100%;
    max-height: 183px;
    height: 100%;
    
    path {
      fill: ${TURQUOISE};
    }
  }
  
  @media screen and (max-width: ${LAPTOP}) {
    max-width: 225px;
    width: 100%;
  }
  
  @media screen and (max-width: ${TABLET}) {
    margin-bottom: 60px;
  }
`;

const Offices = styled.div`
  display: flex;
  flex-direction: column;
`;

const OfficesTitle = styled.h4`
  font-family: BrandonGrotesque;
  font-size: 33px;
  letter-spacing: normal;
  text-align: left;
  text-transform: uppercase;
  color: ${TURQUOISE};
  margin-bottom: 20px;
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 26px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    text-align: center;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 24px;
  }
`;

const OfficesText = styled(ReactMarkdown)`
  & p {
    font-family: AvenirNextLTPro;
    font-size: 21px;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${WHITE};
    display: flex;
    align-items: flex-end;
    
    @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
      font-size: 18px;
    }
    
    @media screen and (max-width: ${TABLET}) {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    
    @media screen and (max-width: ${MOBILE}) {
      font-size: 17px;
    }
  }
`;

const ContactsDataTitle = styled.p`
  font-family: AvenirNextLTProBold;
  font-size: 21px;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: ${TURQUOISE};
  max-width: 250px;
  width: 100%;
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 18px;
    width: 225px;
    max-width: none;
  }
  
  @media screen and (max-width: ${TABLET}) {
    text-align: center;
  }
`;

const LeftContainerMobileContent = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  
  > p:first-child {
    margin-bottom: 10px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    display: flex;
  }
`;

export default Contacts;
