import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Image from 'gatsby-image';
import { TURQUOISE, WHITE } from '../../constants/colors';
import { LAPTOP, MOBILE, TABLET } from '../../constants/screens';

const Introduction = ({
  image: { file: { localFile: { childImageSharp: { sizes } } } }, title, subTitle, description,
}) => (
  <IntroductionContainer>
    <TextWrapper>
      <MainTitle>
        <Title color={WHITE}>
          {title}
        </Title>
        <Title color={TURQUOISE}>
          {subTitle}
        </Title>
      </MainTitle>
      <Text>
        {description}
      </Text>
    </TextWrapper>
    <ImageWrapper>
      <Image
        alt="Planet"
        sizes={sizes}
      />
    </ImageWrapper>
  </IntroductionContainer>
);

Introduction.propTypes = {
  image: PropTypes.shape().isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
};

Introduction.defaultProps = {
  title: '',
  subTitle: '',
  description: '',
};

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;

  @media screen and (max-width: ${MOBILE}) {
    margin-bottom: 50px;
  }
`;

const MainTitle = styled.h1`
  text-align: center;
`;

const Title = styled.span`
  display: block;
  font-family: BrandonGrotesque;
  font-size: 40px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${props => props.color};
  text-transform: uppercase;
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 26px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 24px;
  }
`;

const Text = styled.h2`
  font-family: AvenirNextLTPro;
  font-weight: 400;
  font-size: 26px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${WHITE};
  max-width: 1200px;
  margin-top: 35px;
  
  @media screen and  (max-width: ${LAPTOP}) {
    font-size: 19px;
    max-width: 850px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 18px;
  }
`;

const IntroductionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 300px 5% 0;
  
  @media screen and  (max-width: ${LAPTOP}) {
    padding: 220px 5% 0;
  }
  
  @media screen and (max-width: ${TABLET}) {
    padding: 200px 5% 0;
  }
`;

const ImageWrapper = styled.div`
  max-width: 1700px;
  width: 100%;
  
  @media screen and  (max-width: ${LAPTOP}) {
    max-width: 1000px;
  }
  
  > div {
    width: 100%;
  }
`;

export default Introduction;
